import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CodeConnect from '../views/CodeConnect.vue'
import CodeConnectSmartTv from '../views/CodeConnectSmartTv.vue'
import Welcome from '../views/Welcome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/code-connect-smartTv',
    name: 'Code Connect Smart Tv',
    component: CodeConnectSmartTv
  },
  {
    path: '/code-connect',
    name: 'Code Connect',
    component: CodeConnect
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
