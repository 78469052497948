<template>
  <v-main>
    <v-container fluid class="fill-height code-connect-container">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" sm="12">
          <v-card class="card pb-8 pt-12">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12"
                     sm="12"
                     md="12"
                     lg="6"
                     xl="6"
                     class="d-flex flex-column align-center">
                <qrcode :value="uuid" :options=" { width: 164, height: 164, margin: 2, color: { dark: '#000000CC', light: '#f1f1f1b3'}}" />
                <span class="text-subtitle-1 mt-4">QR Code Connect</span>
                <span class="text-subtitle-2 font-weight-light text-center qr-description pa-2">
                  Connect by scanning this QR Code using your <b>mobile phone camera.</b>
                  To do so, go to the Backoffice and select "<b>Scan QR Code</b>" option under <b>added Device.</b>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import VueQRCode from '@chenfengyuan/vue-qrcode'
import Pusher from 'pusher-js'
import {mapGetters} from "vuex";

export default {
  components: {
    'qrcode': VueQRCode
  },
  computed: {
    ...mapGetters({'device': 'getDevice', 'uuid':'getDeviceUuid'})
  },
  data() {
    return {
      options: {},
      showQRCodeOnly: false,
      code: {
        first: null,
        second: null,
        third: null
      },
      errorMessages: [],
      isFormValid: false,
      currentFocus: 'first',
      inputMask: 'XXXX',
      loading: false,
      rules: {
        required: this.validateField
      }
    }
  },
  mounted() {
    this.subscribeToPusher();
  },
  methods: {
    subscribeToPusher() {
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true
      });
      pusher.subscribe(`device_${this.uuid}`);
      pusher.bind('deviceDetailsUpdate', (data) => {
        console.log(data);
        if (data.type === 'deviceInstalled') {
          let device = Object.assign({}, this.device);
          console.log(device);
          device.name = data.deviceName;
          device.uuid = data.deviceUuid;
          device.is_installed = true;
          let location = Object.assign({}, this.location);
          location.name = data.locationName;
          location.uuid = data.locationUuid;
          let space = Object.assign({}, this.space);
          space.name = data.spaceName;
          space.uuid = data.spaceUuid;
          this.$store.dispatch('updateDeviceDetails', device);
          this.$store.dispatch('updateLocationDetails', location);
          this.$store.dispatch('updateSpaceDetails', space);
          this.$router.replace('/welcome');
        }
      });
    },
    onFocus(e) {
      this.currentFocus = e.currentTarget.id;
    },
    onCancel() {
      this.$refs.connectForm.reset();
      this.$router.push({name: 'Home'});
    },
    onInput() {
      this.isCodeValid();
      if (this.errorMessages.length) this.errorMessages = [];
      if (this.code[this.currentFocus] && this.code[this.currentFocus].length === (12 / 3)) {
        switch (this.currentFocus) {
          case 'first':
            this.$refs.secondInput.focus();
            break;
          case 'second':
            this.$refs.thirdInput.focus();
            break;
          default:
        }
      }
    },
    validateField(value) {
      if (!value) return 'This field is required';
      return true;
    },
    isCodeValid() {
      let finalCode = '';
      Object.keys(this.code)
          .forEach((key) => {
            if (this.code[key] && typeof this.code[key] === 'string') {
              finalCode += this.code[key];
            }
          });
      this.isFormValid = !!finalCode && finalCode.length === 12;
    },

      }
    }
</script>

<style scoped lang="scss">
.actions-container {
  width: 100%;
}

.centered-input input {
  text-align: center
}

.code-connect-container {
  .card {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.1),
    0 16px 24px 2px rgba(0, 0, 0, 0.1);
  }

  .subtitle {
    line-height: 1rem;
  }

  .submit-button {
    &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #2b85d8 !important;
      opacity: 0.5;
      color: white !important;
    }
  }

  .qr-description {
    width: 240px;
  }
  .discovery_code{
    font-size: 50px;
    font-weight: 500;
    color: #2b85d8;
  }
}
</style>


