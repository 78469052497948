<template>
  <v-main>
    <v-container fluid class="fill-height code-connect-container">
      <v-row no-gutters align="center" justify="center" class="mb-2">
        <v-col cols="12" xl="6" lg="6" sm="12">
          <v-card class="pa-2" align="center" justify="center">
              <v-card-title class="justify-center discovery_code mt-4">{{ device.discovery_code.toLowerCase() }}</v-card-title>
              <span class="mt-0 pb-0 mb-0">Device Discovery Code</span>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" sm="12">
          <v-card class="card pa-5">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12"
                     sm="12"
                     md="12"
                     lg="12"
                     xl="6"
                     >
                <v-card-text class="pt-4 px-6">
                  <v-img src="../assets/nsoft.png"
                         class="mx-auto"
                         max-width="56"
                         max-height="56"/>
                  <v-card-title class="justify-center font-weight-regular secondary--text">
                    Code Connect
                  </v-card-title>
                  <div class="subtitle px-5 text-center gray--text">
                    Insert generated code to establish device connection with code provider’s backoffice.
                  </div>
                  <div class="subtitle text-center text-subtitle-2 primary--text mt-1">
                    <a href="#" class="text-decoration-none">Find out more?</a>
                  </div>
                </v-card-text>
                <v-card-text class="px-4">
                  <v-form class="px-5 d-flex justify-center align-center"
                          ref="connectForm">
                    <v-text-field class="centered-input pr-4"
                                  single-line
                                  outlined
                                  dense
                                  id="first"
                                  ref="firstInput"
                                  maxlength="4"
                                  :hide-details="true"
                                  :error="errorMessages.length > 0"
                                  v-model="code.first"
                                  :placeholder="inputMask.toLowerCase()"
                                  @input="onInput"
                                  @focus="onFocus"
                                  @keypress.enter="onSubmitCode"/>
                    <h4>
                      -
                    </h4>
                    <v-text-field class="centered-input pr-4 pl-4"
                                  single-line
                                  outlined
                                  dense
                                  id="second"
                                  ref="secondInput"
                                  hide-details
                                  maxlength="4"
                                  :error="errorMessages.length > 0"
                                  v-model="code.second"
                                  :placeholder="inputMask.toLowerCase()"
                                  @input="onInput"
                                  @focus="onFocus"
                                  @keypress.enter="onSubmitCode"/>
                    <h4>
                      -
                    </h4>
                    <v-text-field class="centered-input pl-4"
                                  single-line
                                  outlined
                                  dense
                                  id="third"
                                  ref="thirdInput"
                                  maxlength="4"
                                  :hide-details="true"
                                  :error="errorMessages.length > 0"
                                  v-model="code.third"
                                  :placeholder="inputMask.toLowerCase()"
                                  @input="onInput"
                                  @focus="onFocus"
                                  @keypress.enter="onSubmitCode"/>
                  </v-form>
                  <span class="error--text pl-5">{{ errorMessages.length > 0 ? errorMessages : null }}</span>
                </v-card-text>
                <v-card-actions class="pt-1 pb-10 px-4">
                  <div class="actions-container px-5">
                    <v-btn block
                           depressed
                           class="submit-button text-none font-weight-regular mb-4"
                           color="primary"
                           :loading="loading"
                           :disabled="!isFormValid"
                           @click.native="onSubmitCode">
                      Connect
                    </v-btn>
                    <v-btn block
                           depressed
                           color="secondary-2"
                           class="text-none font-weight-regular ml-0"
                           @click.native="onCancel">
                      Cancel
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-col>
              <v-col cols="12"
                     sm="12"
                     md="12"
                     lg="6"
                     xl="6"
                     class="d-flex flex-column align-center">
                <qrcode :value="device.uuid" :options=" { width: 164, height: 164, margin: 2, color: { dark: '#000000CC', light: '#f1f1f1b3'}}" />
                <span class="text-subtitle-1 mt-4">QR Code Connect</span>
                <span class="text-subtitle-2 font-weight-light text-center qr-description pa-2">
                  You can also connect by scanning this QR Code using your <b>mobile phone camera.</b>
                  To do so, go to the Backoffice and select "<b>Scan QR Code</b>" option under <b>added Device.</b>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <span class="local-ip">IP Address: {{ device.local_ip_addresses[0] }} </span>
  </v-main>
</template>

<script>
import errors from '../assets/errors';
import VueQRCode from '@chenfengyuan/vue-qrcode'
import Pusher from 'pusher-js'
import {mapGetters} from "vuex";

export default {
  components: {
    'qrcode': VueQRCode
  },
  computed: {
    ...mapGetters({'device': 'getDevice'})
  },
  data() {
    return {
      showQRCodeOnly: false,
      code: {
        first: null,
        second: null,
        third: null
      },
      errorMessages: [],
      isFormValid: false,
      currentFocus: 'first',
      inputMask: 'XXXX',
      loading: false,
      rules: {
        required: this.validateField
      }
    }
  },
  mounted() {
    this.subscribeToPusher();
    this.$nextTick(() => {
      this.$refs.firstInput.focus();
    });
  },
  methods: {
    subscribeToPusher() {
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true
      });
      pusher.subscribe(`device_${this.device.uuid}`);
      pusher.bind('deviceDetailsUpdate', (data) => {
        console.log(data);
        if (data.type === 'deviceInstalled') {
          let device = Object.assign({}, this.device);
          console.log(device);
          device.name = data.deviceName;
          device.uuid = data.deviceUuid;
          device.is_installed = true;
          let location = Object.assign({}, this.location);
          location.name = data.locationName;
          location.uuid = data.locationUuid;
          let space = Object.assign({}, this.space);
          space.name = data.spaceName;
          space.uuid = data.spaceUuid;
          this.$store.dispatch('updateDeviceDetails', device);
          this.$store.dispatch('updateLocationDetails', location);
          this.$store.dispatch('updateSpaceDetails', space);
          this.$router.replace('/welcome');
        }
      });
    },
    onFocus(e) {
      this.currentFocus = e.currentTarget.id;
    },
    onCancel() {
      this.$refs.connectForm.reset();
      this.$router.push({name: 'Home'});
    },
    onInput() {
      this.isCodeValid();
      if (this.errorMessages.length) this.errorMessages = [];
      if (this.code[this.currentFocus] && this.code[this.currentFocus].length === (12 / 3)) {
        switch (this.currentFocus) {
          case 'first':
            this.$refs.secondInput.focus();
            break;
          case 'second':
            this.$refs.thirdInput.focus();
            break;
          default:
        }
      }
    },
    validateField(value) {
      if (!value) return 'This field is required';
      return true;
    },
    isCodeValid() {
      let finalCode = '';
      Object.keys(this.code)
          .forEach((key) => {
            if (this.code[key] && typeof this.code[key] === 'string') {
              finalCode += this.code[key];
            }
          });
      this.isFormValid = !!finalCode && finalCode.length === 12;
    },
    onSubmitCode(e) {
          e.preventDefault();
          if (!this.$refs.connectForm.validate()) return;
          let formattedCode = '';
          Object.keys(this.code).forEach((key) => {
            formattedCode += this.code[key];
          });
          this.loading = true;
          console.log(formattedCode)
          this.$sdk.installDevice(formattedCode).then(response => {
            if(response.installation_success === true){
              this.$store.dispatch('updateDeviceDetails', response.device);
              this.$store.dispatch('updateLocationDetails', response.location);
              this.$store.dispatch('updateSpaceDetails', response.space);
              this.$router.replace('/welcome');
            }else if(response.installation_success === false){
              let data = JSON.parse(response.error_message)
              this.errorMessages = data.message
            }
          }).catch(error => {
            this.errorMessages.push(errors[error['messageError']]);
          }).finally(() => {
            this.loading = false;
          });
        }
      }
    }
</script>

<style scoped lang="scss">
.actions-container {
  width: 100%;
}

.centered-input input {
  text-align: center
}

.code-connect-container {
  .card {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.1),
    0 16px 24px 2px rgba(0, 0, 0, 0.1);
  }

  .subtitle {
    line-height: 1rem;
  }

  .submit-button {
    &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #2b85d8 !important;
      opacity: 0.5;
      color: white !important;
    }
  }

  .qr-description {
    width: 240px;
  }
  .discovery_code{
    font-size: 50px;
    font-weight: 500;
    color: #2b85d8;
  }
}
</style>

