import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import sdk from './plugins/sdk';
import './styles/main.scss';

Vue.config.productionTip = false

function getQueryParams() {
  const queryString = window.location.search.substring(1);
  const params = {};
  queryString.split('&').forEach(param => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value);
  });
  return params;
}

const params = getQueryParams();
console.log('Query Parameters:', params);

if (params.options && params.uuid) {
  const options = JSON.parse(params.options);
  console.log('Options:', options);
  console.log('UUID:', params.uuid);
  store.commit('setDeviceUUID', params.uuid);
  // Handle case for URL 1
  // Mount the Vue instance directly
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
} else if (params.options && params.appRunUuid) {
  const options = JSON.parse(params.options);
  console.log('Options:', options);
  console.log('App Run UUID:', params.appRunUuid);
  store.commit('setDeviceUUID', params.appRunUuid);
  // Handle case for URL 2
  // Initialize the SDK first
  sdk.initializeSDK(store).then((sdk) => {
    Vue.prototype.$sdk = sdk;
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }).catch(error => {
    console.error('SDK Initialization Error:', error);
  });
} else {
Vue.config.productionTip = false
const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})
sdk.initializeSDK(store).then((sdk) => {
  Vue.prototype.$sdk = sdk;
  app.$mount('#app');
});
}
