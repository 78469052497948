<template>
  <v-main class="welcome-page">
    <v-container fluid
                 class="fill-height">
      <v-row no-gutters
             align="center"
             justify="center">
        <v-col cols="12"
               sm="8"
               md="6"
               lg="4"
               xl="3">
          <v-card>
            <v-card-text class="pa-10">
              <v-img src="../assets/nsoft.png"
                     class="mx-auto"
                     max-width="56"
                     max-height="56" />
              <v-card-title class="justify-center font-weight-regular secondary--text">
                Code Connect
              </v-card-title>
              <div class="subtitle px-5 text-center">
                It seems there are no configured apps for this device yet.
              </div>
              <v-list class="px-5">
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="device-info device-info-title">
                      Space
                    </div>
                    <div class="device-info float-right text-right font-weight-bold">
                      {{ space.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="device-info device-info-title">
                      Location
                    </div>
                    <div class="device-info float-right text-right font-weight-bold">
                      {{ location.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="device-info device-info-title">
                      Device
                    </div>
                    <div class="device-info float-right text-right font-weight-bold">
                      {{ device.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-10" />
              </v-list>
              <div class="subtitle px-5 text-center">
                Display will automatically refresh as soon as app configuration is assigned via backoffice tool.
              </div>
            </v-card-text>
            <v-card-actions class="px-10 pb-10">
              <div class="actions-container px-5">
                <v-btn block
                       depressed
                       class="secondary-btn text-none font-weight-regular"
                       @click="goToBackOffice"
                >
                  Sign in to Backoffice
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'Welcome',
  computed: {
    ...mapGetters({
      device: 'getDevice',
      space: 'getSpace',
      location: 'getLocation',
    })
  },
  mounted(){
    if (!this.device.is_installed) {
      this.$router.replace('/code-connect');
    }
  },
  methods: {
    goToBackOffice() {
      window.location = 'https://devices.nsoft.com';
    }
  }
}
</script>
<style scoped lang="scss">
.welcome-page {
  .subtitle {
    word-break: break-word;
  }

  .device-info {
    width: 50% !important;

    &-title {
      color: $color-text-secondary;
    }
  }

  .v-list-item__content {
    .device-info {
      flex: none;
    }
  }
}
</style>
