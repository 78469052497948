<template>
  <v-main>
    <v-container fluid
                 class="installation-base-container fill-height">
      <v-row no-gutters
             align="center"
             justify="center">
        <v-col cols="12"
               sm="8"
               md="6"
               lg="4"
               xl="3">
          <v-card>
            <v-card-text class="pa-10">
              <v-img src="../assets/nsoft.png"
                     class="mx-auto"
                     max-width="56"
                     max-height="56" />
              <v-card-title class="justify-center font-weight-regular secondary--text">
                Code Connect
              </v-card-title>
              <div class="subtitle px-5 text-center">
                Insert generated code to establish device connection with code provider’s backoffice.
              </div>
              <div class="subtitle text-center text-subtitle-2 primary--text px-5">
                <a href="#" class="text-decoration-none">Find out more?</a>
              </div>
            </v-card-text>
            <v-card-actions class="px-10 pb-10">
              <div class="actions-container px-5 w-100">
                <v-btn block
                       depressed
                       class="text-none font-weight-regular mb-4 body-1"
                       color="primary"
                       @click="goToBackOffice"
                       >
                  Sign In
                </v-btn>
                <v-btn block
                       depressed
                       append
                       color="secondary-2"
                       class="secondary-btn text-none font-weight-regular ml-0 body-1"
                       :to="{ path: 'code-connect' }">
                  Code Connect
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  mounted() {
    this.handleQueryParams();
  },
  methods: {
    handleQueryParams() {
    let options = this.$route.query.options;
    if (options) {
      try {
        let optionsUrl = JSON.parse(decodeURIComponent(options));
        if (optionsUrl.endpoint === "none" && optionsUrl.type === "android") {
          this.$router.push('/code-connect-smartTv');
        }else{
          this.$router.push('/code-connect');
        }
      } catch (e) {
        console.error('Failed to parse options:', e);
      }
    }else{
          this.$router.push('/code-connect');
        }
  },
    goToBackOffice () {
      window.location = 'https://devices.nsoft.com'
    }
  }
}
</script>
<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>