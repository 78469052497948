import {Core, Relay} from "@nsftx/systems-sdk";

let relay;
let store;

export default {
  relay,
  initializeSDK(storeInstance) {
    return new Promise(resolve => {
      store = storeInstance;
      let sys = new Core('code-connect');
      sys.connect();
      return sys.on('connection', () => {
        relay = new Relay(sys);
        return relay.getState().then((response) => {
          let parsed = JSON.parse(response.data);
          store.dispatch('updateDeviceDetails', parsed.device);
          store.dispatch('updateLocationDetails', parsed.location);
          store.dispatch('updateSpaceDetails', parsed.space);
          resolve(relay);
        });
      });
    });
  }
}