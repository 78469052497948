<template>
  <v-app>
    <v-container class="fill-height">
      <v-row>
        <router-view/>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Pusher from 'pusher-js';
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  computed: {
    ...mapGetters({ device: 'getDevice' })
  },

  mounted() {
    if (this.device.is_installed && this.$route.name !== 'Welcome') {
      this.$router.replace('/welcome');
    }

    this.subscribeToPusher();
  },

  methods: {
    subscribeToPusher() {
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        forceTLS: true
      });

      // Add your Pusher subscription logic here
      const channel = pusher.subscribe(`device_${this.device.uuid}`);
      channel.bind('deviceDetailsUpdate', data => {
        if (data.type === 'deviceInstalled') {
          let device = { ...this.device };
          device.name = data.deviceName;
          device.uuid = data.deviceUuid;
          device.is_installed = true;
          let location = { ...this.location };
          location.name = data.locationName;
          location.uuid = data.locationUuid;
          let space = { ...this.space };
          space.name = data.spaceName;
          space.uuid = data.spaceUuid;
          this.$store.dispatch('updateDeviceDetails', device);
          this.$store.dispatch('updateLocationDetails', location);
          this.$store.dispatch('updateSpaceDetails', space);
          this.$router.replace('/welcome');
        }
      });
    }
  }
};
</script>

<style lang="scss">
::-webkit-input-placeholder {
  text-align: center !important;
}

.row {
  margin-top: 0;
  margin-bottom: 0;
}
.row + .row {
  margin-top: 0;
}
body {
  letter-spacing: normal !important;
}
.v-application {
  background-color: #f1f1f1 !important;

  &:not(input) {
    user-select: none;
  }
}
</style>
