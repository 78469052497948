import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#2b85d8',
        'secondary-2': '#e4e5e6',
        error: '#ec4234',
      },
    },
  },
});
