import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    getDevice(state) {
      return state.device
    },
    getSpace(state) {
      return state.space
    },
    getLocation(state) {
      return state.location
    },
    getDeviceUuid(state){
      return state.device_uuid
    }
  },
  state: {
    device_uuid:"",
    device: {},
    location: {},
    space: {},
  },
  mutations: {
    SET_DEVICE(state, data) {
      state.device = data;
    },
    SET_LOCATION(state, data) {
      state.location = data;
    },
    SET_SPACE(state, data) {
      state.space = data;
    },
    setDeviceUUID(state,data){
      state.device_uuid = data;
    }

  },
  actions: {
    updateDeviceDetails({commit}, data) {
      commit('SET_DEVICE', data);
    },
    updateSpaceDetails({commit}, data) {
      commit('SET_SPACE', data);
    },
    updateLocationDetails({commit}, data) {
      commit('SET_LOCATION', data);
    }
  },
  modules: {
  }
})
